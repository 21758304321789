<template>
  <div class='icon-container'>
    <i class='loader'></i>
  </div>
</template>

<style>
  .icon-container {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
  }
  .loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
  }
  @keyframes around {
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(360deg)
    }
  }
  .loader::after, .loader::before {
    content: '';
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #333 #333 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }
  .loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
</style>
